import networks from '@htong/net'
import { xdpartner } from '@api/config.js'
const { sysPoint } = networks
// SI自主注册
export function siRegister (data) {
  return xdpartner(data, { method: 'post', _code: 'si.register' })
}

// 获取验证码
export function sendSms (param) {
  return xdpartner(param, { _code: 'login.sendSms' })
}
// 验证码登陆
export function smsCodeLogin (data) {
  return xdpartner(data, { method: 'post', _code: 'login.smsCode' })
}
// 校验验证码
export function validateSmsCode (data) {
  return xdpartner(data, { _code: 'agentUser.validateSmsCode', _text: '校验中...' })
}

// openid登陆
export function openidLogin (openId) {
  return xdpartner({ openId }, { _code: 'login.openId' })
}
// 获取微信授权链接
export function oauth2gurl (data) {
  return sysPoint.wx(data, { method: 'post', _code: 'oauth2.gurl', _text: '请求中...' })
}
// openid获取微信用户信息
export function oauth2usrinf (openid) {
  return sysPoint.wx({ openid }, { _code: 'oauth2.usrinf', _text: '请求中...' })
}
// 获取代理人个人信息
export function getAgentUserInfo ({ id, token }) {
  const data = id ? { id } : {}
  return xdpartner(data, { _code: 'agentUser.getAgentUserInfo', headers: { 'x-access-token': token } })
}

// 获取代理人个人信息-无拦截
export function getAgentNoIntercept (code) {
  return xdpartner({ code }, { _code: 'agent.no.intercept', headers: { 'x-access-token': '' } })
}
