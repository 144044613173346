import PageUtilCom from './pageUtil.vue'

export default {
  component: null,
  create (Vue) {
    if (this.component) {
      this.component.showUtil()
      return this.component
    }
    const vm = new Vue({
      render (h) {
        return h(PageUtilCom)
      }
    }).$mount()
    document.body.appendChild(vm.$el)
    this.component = vm.$children[0]
    return this.component
  },
  hide () {
    this.component && this.component.hideUtil()
  }
}
