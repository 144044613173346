import { addLog } from '@/api/log'
import store from '@/store'

/**
 * 日志上报
 * @param {*} option
 * openId,
 * partnerCode, [M]
 * actionCode, [M]
 *  T00001访问产品，T00002访问首页，T00003访问文章，H00001合伙人登陆，H00002合伙人注册，H00003管家通知，H00004产品申请
 * userId,
 * portrait,
 * nickname,
 */
export default function log (option = {}) {
  try {
    let {
      openId = store.getters.openid,
      partnerCode = store.getters.userInfo && store.getters.userInfo.code, // TODO
      actionCode,
      userId, portrait, nickname
    } = option
    console.log(openId, partnerCode, actionCode)
    if (!openId || !partnerCode || !actionCode) {
      return
    }

    if (!userId) {
      userId = store.getters.userInfo ? store.getters.userInfo.id : ''
    }
    if (!portrait) {
      portrait = (store.getters.userInfo && store.getters.userInfo.portrait) || (store.getters.wxInfo && store.getters.wxInfo.headimgurl) || ''
    }
    if (!nickname) {
      nickname = (store.getters.userInfo && store.getters.userInfo.realname) || (store.getters.wxInfo && store.getters.wxInfo.nickname) || ''
    }

    const data = {
      ...option,

      openId,
      partnerCode,
      actionCode,

      userId,
      portrait,
      nickname,
      source: 'HHR'
    }

    addLog(data)
  } catch (error) {
    console.warn(error)
  }
}
