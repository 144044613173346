import { xdpartnerLog } from '@api/config.js'
// 获取访客列表
export function getMyVisitors (param) {
  return xdpartnerLog(param, {
    method: 'get',
    _url: '/logCustomer/myVisitors'
  })
}
// 获取访客统计总数
export function getVisitorsTotalNum (param) {
  return xdpartnerLog(param, {
    method: 'get',
    _url: '/logCustomer/getViews'
  })
}
export function addLog (data) {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  return xdpartnerLog(formData, {
    method: 'post',
    _url: '/logCustomer/addLog'
  })
}

export function getViews (agentUserCode) {
  return xdpartnerLog(agentUserCode, {
    _url: '/logCustomer/getViews'
  })
}
