var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "pageutil",
      attrs: { id: "util-wrap" },
      on: {
        touchstart: _vm.handleStart,
        touchmove: _vm.handleMove,
        touchend: _vm.handleEnd,
      },
    },
    [
      _c(
        "div",
        { staticClass: "phone" },
        [
          _c("van-icon", {
            staticStyle: { "vertical-align": "middle", "margin-right": "3px" },
            attrs: { name: "phone", size: "26px", color: "#FD5042" },
          }),
          _c("span", { staticClass: "phone-text" }, [_vm._v("客服电话")]),
        ],
        1
      ),
      _c("a", { staticClass: "phone-a", attrs: { href: _vm.phone } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }