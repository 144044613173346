import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import common from '@/assets/js/common'
import config from '@/config/index'
import { oauth2gurl } from '@/api/login'
import sendLog from '@/assets/js/log'
import pageUtil from '@/components/page-util'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { path: '/home' }
    },
    {
      path: '/login', // 登录
      name: 'login',
      component: () => import('@comps/EmptyView.vue'),
      redirect: { name: 'agentlogin' },
      meta: { loginAuth: 0 },
      children: [
        {
          path: 'index',
          name: 'agentlogin',
          component: () => import('@views/login/login.vue'),
          meta: { loginAuth: 0 }
        },
        {
          path: 'register',
          name: 'agentregister',
          component: () => import('@views/login/agentregister.vue'),
          meta: { loginAuth: 0, showUtil: true }
        }
      ]
    },
    {
      path: '/home', // 首页
      name: 'home',
      component: () => import('@comps/EmptyView.vue'),
      redirect: { name: 'producthome' },
      meta: { loginAuth: 0 },
      children: [
        {
          path: 'index',
          name: 'producthome',
          component: () => import('@/views/product/producthome.vue'),
          meta: { loginAuth: 1 }
        },
        {
          path: 'steward',
          name: 'servicesteward',
          component: () => import('@views/home/servicesteward.vue'),
          meta: { loginAuth: 0 }
        },
        {
          path: 'detail',
          name: 'proddetail',
          component: () => import('@/views/product/proddetail.vue'),
          meta: { loginAuth: 0 }
        }
      ]
    },
    {
      path: '/showbusi', // 展业攻略
      name: 'showbusi',
      meta: { loginAuth: 2 },
      component: () => import('@comps/EmptyView.vue'),
      redirect: { name: 'posterlist' },
      children: [
        {
          path: 'list', // 海报列表
          name: 'posterlist',
          meta: { loginAuth: 2 },
          component: () => import('@views/showbusi/posterlist.vue')
        }
      ]
    },
    {
      path: '/mine', // 我的
      name: 'mine',
      meta: { loginAuth: 0 },
      component: () => import('@comps/EmptyView.vue'),
      redirect: { name: 'mineindex' },
      children: [
        {
          path: 'index', // 我的首页
          name: 'mineindex',
          meta: { loginAuth: 1 },
          component: () => import('@views/mine/mine.vue')
        },
        {
          path: 'earnings', // 我的收益
          name: 'myearnings',
          meta: { loginAuth: 0 },
          component: () => import('@/views/mine/earning/myearnings.vue')
        },
        {
          path: 'orders', // 我的订单
          name: 'myorders',
          meta: { loginAuth: 1 },
          component: () => import('@/views/mine/order/myorder.vue')
        },
        {
          path: 'partners', // 我的合伙人
          name: 'mypartners',
          meta: { loginAuth: 0 },
          component: () => import('@views/mine/mypartner.vue')
        },
        {
          path: 'visitors', // 我的访客
          name: 'myvisitors',
          meta: { loginAuth: 0 },
          component: () => import('@views/mine/myvisitor.vue')
        },
        {
          path: 'rebate', // 我的返佣
          name: 'myrebate',
          meta: { loginAuth: 0 },
          component: () => import('@views/mine/myrebate.vue')
        },
        {
          path: 'notices', // 我的消息
          name: 'mynotices',
          meta: { loginAuth: 0 },
          component: () => import('@views/mine/mynotice.vue')
        },
        {
          path: 'setting', // 我的设置
          name: 'mysetting',
          meta: { loginAuth: 0 },
          component: () => import('@comps/EmptyView.vue'),
          redirect: { name: 'settingindex' },
          children: [
            {
              path: 'index', // 设置首页
              name: 'settingindex',
              meta: { loginAuth: 1 },
              component: () => import('@views/mine/setting/setting.vue')
            },
            {
              path: 'personinfo', // 个人信息
              name: 'personinfo',
              meta: { loginAuth: 1 },
              component: () => import('@views/mine/setting/personinfo.vue')
            },
            {
              path: 'pchange', // 修改手机号
              name: 'phonechange',
              meta: { loginAuth: 1 },
              component: () => import('@views/mine/setting/changephone.vue')
            },
            {
              path: 'pchangeresult', // 修改手机号完成
              name: 'pchangeresult',
              meta: { loginAuth: 0 },
              component: () => import('@views/mine/setting/changePhoneResult.vue')
            },
            {
              path: 'realname', // 实名认证
              name: 'realname',
              meta: { loginAuth: 0 },
              redirect: { name: 'realnamestatus' },
              component: () => import('@comps/EmptyView.vue'),
              children: [
                {
                  path: 'status', // 实名认证状态
                  name: 'realnamestatus',
                  meta: { loginAuth: 1 },
                  component: () => import('@views/mine/setting/realname/realname.vue')
                },
                {
                  path: 'idcard', // 实名认证上传身份证实名
                  name: 'realnameidcard',
                  meta: { loginAuth: 1 },
                  component: () => import('@views/mine/setting/realname/idcard.vue')
                }
              ]
            },
            {
              path: 'actmanage', // 结算账户管理
              name: 'actmanage',
              meta: { loginAuth: 0 },
              redirect: { name: 'actmanageindex' },
              component: () => import('@comps/EmptyView.vue'),
              children: [
                {
                  path: 'index', // 结算账户管理首页
                  name: 'actmanageindex',
                  meta: { loginAuth: 1 },
                  component: () => import('@views/mine/setting/actmanage/actmanage.vue')
                },
                {
                  path: 'addcard', // 添加/修改银行卡
                  name: 'actmanageaddcard',
                  meta: { loginAuth: 1 },
                  component: () => import('@views/mine/setting/actmanage/addbankcard.vue')
                },
                {
                  path: 'step', // 税银注册步骤
                  name: 'registerstep',
                  meta: { loginAuth: 0 },
                  component: () => import('@views/mine/setting/actmanage/operatestep.vue')
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/s', // 分享
      name: 'share',
      meta: { loginAuth: 0 },
      component: () => import('@comps/EmptyView.vue'),
      redirect: { name: 'home' },
      children: [
        {
          path: 'article', // 文章素材分享页
          name: 'article',
          meta: { loginAuth: 0 },
          component: () => import('@views/share/article.vue')
        }
      ]
    },
    {
      path: '/t', // 工具页面
      name: 'tool',
      meta: { loginAuth: 0 },
      component: () => import('@comps/EmptyView.vue'),
      redirect: { name: 'pverify' },
      children: [
        {
          path: 'pverify', // 登录手机验证
          name: 'pverify',
          meta: { loginAuth: 1 },
          component: () => import('@views/toolpage/verifyphone.vue')
        }
      ]
    }
  ]
})

// 全局前置路由鉴权
router.beforeEach(async (to, from, next) => {
  // 0/undefined:完全不需要登陆（不需要用户信息）;  1:必须强制登陆（获取用户信息）;  2:可以尝试自动登陆(页面能用用户信息)
  const loginAuth = to.meta.loginAuth
  const { openid } = to.query

  if (openid) {
    store.commit('SET_OPENID', openid)
    delete to.query.openid
    delete to.query.appid
    next(to)
    return
  }
  if (common.isWeiXinWeb() && !store.getters.openid) {
    redirectOpenid(to)
    return
  }
  if (!store.getters.wxInfo && store.getters.openid && process.env.NODE_ENV !== 'development') {
    await store.dispatch('wxInfo')
  }

  if (to.meta.actionCode) {
    sendLog({ actionCode: to.meta.actionCode })
  }
  if (to.meta.showUtil) {
    pageUtil.create(Vue)
  } else {
    pageUtil.hide()
  }
  if (!loginAuth || store.getters.userInfo) {
    next()
    return
  }

  if (store.getters.xdptoken) {
    await store.dispatch('userInfo')
  } else if (store.getters.openid && !to.query.reLogin) {
    await store.dispatch('openidLogin')
    await store.dispatch('userInfo')
  }

  if (store.getters.userInfo) {
    next()
  } else if (loginAuth === 1) {
    next(`/login/index?redirect=${encodeURIComponent(to.fullPath)}`)
  } else {
    next()
  }
})

function redirectOpenid (to) {
  const baseUrl = (process.env.VUE_APP_BASEURL === '/') ? '' : process.env.VUE_APP_BASEURL
  const registerUrl = `${config.domain}${baseUrl}${to.fullPath}`
  const snsapiBase = store.getters.snsapiBase
  const data = {
    appId: store.getters.appid || config.appid,
    registerUrl,
    snsapiBase
  }
  oauth2gurl(data).then(res => {
    if (res.success && res.datas) {
      window.location.href = res.datas
    } else {
      console.warn(res.message)
    }
  })
}

export default router
