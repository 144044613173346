import Vue from 'vue'
import Vuex from 'vuex'
import { openidLogin, smsCodeLogin, getAgentUserInfo, oauth2usrinf } from '@/api/login'
import config from '@/config/index'
Vue.use(Vuex)

const state = {
  token: '',
  appid: config.appid,
  openid: process.env.NODE_ENV !== 'development' ? '' : 'opgau0bvK2EaFtsdHjMAycxBvgda',
  snsapiBase: false, // snsapi_userinfo授权
  userInfo: null,
  wxInfo: null
}

const getters = {
  xdptoken: state => state.token || sessionStorage.getItem('xdptoken'),
  appid: state => state.appid,
  openid: state => state.openid || sessionStorage.getItem('openid'),
  snsapiBase: state => state.snsapiBase,
  userInfo: state => state.userInfo,
  wxInfo: state => state.wxInfo
}

const mutations = {
  SET_TOKEN: (state, token) => {
    sessionStorage.setItem('xdptoken', token)
    state.token = token
  },
  SET_OPENID: (state, openid) => {
    sessionStorage.setItem('openid', openid)
    state.openid = openid
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_WX_INFO: (state, wxInfo) => {
    state.wxInfo = wxInfo
  }
}

const actions = {
  smsLogin ({ dispatch, commit, getters }, data) {
    return smsCodeLogin(data).then(res => {
      if (res.success && res.data && res.data.token) {
        commit('SET_TOKEN', res.data.token)
      } else {
        console.warn(`[store] ${res.message}`)
        dispatch('clearLogin')
      }
      return res
    })
  },
  openidLogin ({ dispatch, commit, getters }) {
    return openidLogin(getters.openid).then(res => {
      if (res.success && res.data && res.data.token) {
        commit('SET_TOKEN', res.data.token)
      } else {
        console.warn(`[store] ${res.message}`)
        dispatch('clearLogin')
      }
      return res.data
    })
  },
  userInfo ({ dispatch, commit, getters }, id) {
    return getAgentUserInfo({ id, token: getters.xdptoken }).then(res => {
      if (res.success && res.data) {
        const userInfo = res.data
        if (!userInfo.portrait && getters.wxInfo) {
          userInfo.portrait = getters.wxInfo.headimgurl
        }
        if (!userInfo.realname && getters.wxInfo) {
          userInfo.realname = getters.wxInfo.nickname
        }
        commit('SET_USER_INFO', userInfo)
        return userInfo
      } else {
        console.warn(`[store] ${res.message}`)
        dispatch('clearLogin')
      }
    })
  },
  wxInfo ({ commit, getters }) {
    return oauth2usrinf(getters.openid).then(res => {
      if (res.success && res.datas) {
        commit('SET_WX_INFO', res.datas)
      }
      return res.datas
    })
  },
  clearLogin ({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_USER_INFO', null)
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
