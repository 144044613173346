// 一些全局的config配置
const modeUrlObj = {
  // 生产环境
  production: {
    orginUrl: window.location.origin,
    domain: location.protocol + '//pnr.91hanhua.com',
    downloadUrl: window.location.origin + '/gfs/',
    uploadUrl: '',
    basePath: process.env.VUE_APP_BASEURL,
    appid: 'wxe452444563317ca8'
  },
  // 开发环境
  development: {
    orginUrl: window.location.origin,
    domain: 'http://pnrsit.91hanhua.com',
    downloadUrl: location.protocol + '//pnrsit.91hanhua.com/gfs/',
    uploadUrl: '',
    basePath: '',
    appid: 'wx58e7fd65b64dae94'
  },
  // 测试环境
  sit: {
    orginUrl: window.location.origin,
    domain: location.protocol + '//pnrsit.91hanhua.com',
    downloadUrl: window.location.origin + '/gfs/',
    uploadUrl: '',
    basePath: process.env.VUE_APP_BASEURL,
    appid: 'wx58e7fd65b64dae94'
  },
  // 测试环境
  uat: {
    orginUrl: window.location.origin,
    domain: location.protocol + '//htuat.hanhua.com',
    downloadUrl: window.location.origin + '/gfs/',
    uploadUrl: '',
    basePath: process.env.VUE_APP_BASEURL,
    appid: 'wx58e7fd65b64dae94'
  }
}
const configObj = {
  ...modeUrlObj[process.env.NODE_ENV]
}

// export default modeUrlObj[process.env.NODE_ENV]
export default configObj
