import networks from '@htong/net'
import store from '@/store'
import { Toast } from 'vant'
import router from '@/router'
const { sysPoint } = networks

// 合伙人业务
export function xdpartner (params, config, datas) {
  if (config._code) {
    config._proxy = { point: 'pnr.customer', code: config._code }
  }
  const token = sessionStorage && sessionStorage.getItem('xdptoken')
  if (token) {
    config.headers = { 'x-access-token': token, ...config.headers }
  }
  return sysPoint.customer(params, config, datas).then(res => {
    if (res.code === 401) {
      emptyTkInfo(res)
    }
    return res
  })
}

// 合伙人日志 _proxyurl
export function xdpartnerLog (params, config, datas) {
  const accesstoken = sessionStorage && sessionStorage.getItem('xdptoken')
  if (accesstoken) {
    config.headers = { accesstoken, ...config.headers }
  }
  if (config._url) {
    config._url = '/logsystem' + config._url
  }
  return sysPoint.customer(params, config, datas)
}

function emptyTkInfo (res) {
  Toast('登录信息失效，请重新登录')
  store.dispatch('clearLogin')
  setTimeout(() => {
    router.push('/login')
  }, 0)
}

export function request (url, option = {}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(option.method || 'get', url)
    xhr.onload = () => {
      resolve(xhr.responseText)
    }
    xhr.onerror = err => {
      reject(err)
    }
    xhr.send(option.data || null)
  })
}
