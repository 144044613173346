
// 下载图片
export function downloadImg (src, fileName) {
  const img = new Image()
  img.src = src
  img.onload = function () {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, img.width, img.height)
    canvas.toBlob(blob => {
      const blobUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = blobUrl
      a.download = fileName || `hhr_${Date.now()}.jpg`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(blobUrl)
    }, 'image/jpeg')
  }
}

// 文本拷贝
export function copyText (text) {
  const input = document.createElement('input')
  input.setAttribute('type', 'text')
  input.setAttribute('value', text)
  input.setAttribute('readonly', true)
  input.style.position = 'absolute'
  input.style.left = '-999px'
  input.style.top = '-999px'
  document.body.appendChild(input)
  input.focus() // input.select()
  input.setSelectionRange(0, input.value.length)
  // console.log(input)
  let result = false
  try {
    result = document.execCommand('copy', true)
  } catch (error) {
    console.warn('execCommand error')
  }
  document.body.removeChild(input)
  return result
}

/**
 * 对象转请求参数格式字符串
 * @returns {String}
 */
export function objectToQuery (/* Object */ map) {
  const backstop = {}
  const enc = encodeURIComponent
  const pairs = []
  for (const name in map) {
    const value = map[name]
    if (value !== backstop[name]) {
      const assign = enc(name) + '='
      if (value instanceof Array) {
        for (let i = 0, l = value.length; i < l; ++i) {
          pairs.push(assign + enc(value[i]))
        }
      } else {
        pairs.push(assign + enc(value))
      }
    }
  }
  return pairs.join('&') // String
}

export function queryToObject (str) {
  const dec = decodeURIComponent
  const qp = str.split('&')
  const ret = {}
  let name = ''
  let val = ''
  for (let i = 0, l = qp.length, item; i < l; ++i) {
    item = qp[i]
    if (item.length) {
      const s = item.indexOf('=')
      if (s < 0) {
        name = dec(item); val = ''
      } else {
        name = dec(item.slice(0, s))
        val = dec(item.slice(s + 1))
      }

      if (typeof ret[name] === 'string') { // inline'd type check
        ret[name] = [ret[name]]
      }

      if (Array.isArray(ret[name])) {
        ret[name].push(val)
      } else { ret[name] = val }
    }
  }
  return ret // Object
}

export function stringToObject (url) {
  try {
    let parms = {}
    if (url.indexOf('?') >= 0) {
      let search = url
      if (search.indexOf('?') >= 0) {
        search = search.substring(search.indexOf('?') + 1, search.length) || ''
      }
      parms = queryToObject(search)
      parms.http = url.substring(0, url.indexOf('?'))
    } else {
      parms.http = url
    }
    return parms
  } catch (e) { return {} }
}

/**
 * 链接重写
 */
export function linkRewrite (url, parms) {
  const linkparm = stringToObject(url)
  let link = linkparm.http
  delete linkparm.http
  const newParm = { ...linkparm, ...parms }
  link = link + '?' + objectToQuery(newParm)
  return link
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}
