<template>
  <div id="util-wrap"
    ref="pageutil"
    v-show="show"
    @touchstart="handleStart"
    @touchmove="handleMove"
    @touchend="handleEnd">
      <div class="phone">
        <van-icon name="phone" size="26px" color="#FD5042" style="vertical-align: middle;margin-right:3px;"/>
        <span class="phone-text">客服电话</span>
      </div>
      <a class="phone-a" :href="phone"></a>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: true,
      phone: 'tel:023-88393304',
      pos: {
        x: +(sessionStorage.getItem('util.pos.x') || 10),
        y: +(sessionStorage.getItem('util.pos.y') || 100),
        sX: 0,
        sY: 0,
        eX: 0,
        eY: 0
      }
    }
  },
  mounted () {
    const { x, y } = this.fixPos(this.pos.x, this.pos.y)
    this.pos.x = x
    this.pos.y = y
    this.setPos(this.pos.x, this.pos.y)
  },
  methods: {
    hideUtil () {
      this.show = false
    },
    showUtil () {
      this.show = true
    },
    setPos (x, y) {
      const $pageutil = this.$refs.pageutil
      $pageutil.style.right = x + 'px'
      $pageutil.style.bottom = y + 'px'
    },
    fixPos (x, y) {
      const $pageutil = this.$refs.pageutil
      const offsetWidth = $pageutil.offsetWidth || 110
      const offsetHeight = $pageutil.offsetHeight || 40
      if (x + offsetWidth > innerWidth) {
        x = innerWidth - offsetWidth
      }
      if (y + offsetHeight > innerHeight) {
        y = innerHeight - offsetHeight
      }
      if (x < 0) { x = 0 }
      if (y < 0) { y = 0 }
      return {
        x, y
      }
    },
    handleStart (e) {
      this.pos.sX = e.touches[0].pageX
      this.pos.sY = e.touches[0].pageY
    },
    handleMove (e) {
      if (e.touches.length > 0) {
        const offsetX = e.touches[0].pageX - this.pos.sX
        const offsetY = e.touches[0].pageY - this.pos.sY
        const calcX = this.pos.x - offsetX
        const calcY = this.pos.y - offsetY
        const { x, y } = this.fixPos(calcX, calcY)
        this.setPos(x, y)
        this.pos.eX = x
        this.pos.eY = y
        e.preventDefault() // TODO
      }
    },
    handleEnd (e) {
      this.pos.sX = 0
      this.pos.sY = 0
      this.pos.x = this.pos.eX
      this.pos.y = this.pos.eY
      sessionStorage.setItem('util.pos.x', this.pos.x)
      sessionStorage.setItem('util.pos.y', this.pos.y)
    }
  }

}
</script>

<style scoped>
  #util-wrap{
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 9999;
    touch-action: none;
  }
  #util-wrap .phone{
    display: inline-block;
    box-sizing: border-box;
    height: 40px;
    width: 110px;
    padding: 6.6px 12px 0 12px;
    border-radius: 20px;
    background-color: #fff;
    border:1px solid #dad8d8;
    text-align: center;
    box-shadow: 0 0.5px 1px 0 rgba(0,0,0,.13);
  }
  .phone-text{
    display: inline-block;
    font-size: 12px;
    color: #666;
    border-left: 1px solid #999;
    padding: 4px 0;
    padding-left: 3px;
    vertical-align: baseline;
  }
  #util-wrap .phone-a{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
  }
</style>
